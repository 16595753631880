import React from 'react'
import PropTypes from 'prop-types'

export const FmbmFooter = ({ fmbmFooter, classes }) => (
  <div className={classes.root}>
    <span className={classes.words}>Made with Love - For Makers By Makers</span>
  </div>
)

FmbmFooter.propTypes = {
  classes: PropTypes.object, // from enhancer (withStyles)
  fmbmFooter: PropTypes.object, // from enhancer (firestoreConnect + connect)
}

export default FmbmFooter
