import { reduce, merge, union } from 'lodash'
import { getFeathersClient } from 'shared/api/feathers'
import { feathersAuthentication } from '../api/feathers'
import reduxifyServices from 'feathers-redux'

/**
 * Updates an entity cache in response to any action with response.entities.
 * @param {Object} state - Redux state
 * @param {Object} action - Redux action
 */
export function entityIds(state = {}, action) {
  const { service, payload } = action
  if (payload && payload.entities) {
    if (typeof payload.result === 'number') payload.result = [payload.result]

    return merge({}, state, {
      [service]: state[service]
        ? union(state[service], payload.result)
        : payload.result,
    })
  }
  return state
}

/**
 * Updates an entity cache in response to any action with response.entities.
 * @param {Object} state - Redux state
 * @param {Object} action - Redux action
 */
export function entities(state = {}, action) {
  const { payload } = action
  if (payload && payload.entities) {
    return merge({}, state, payload.entities)
  }
  return state
}

/**
 * Updates an entity cache in response to any action with response.entities.
 * @param {Object} state - Redux state
 * @param {Object} action - Redux action
 */
function serviceTotals(state = {}, action) {
  const { service, payload } = action
  if (payload && payload.total) {
    return merge({}, state, { [service]: payload.total })
  }
  return state
}

/**
 * Redux reducer for errors stored by service
 * @param {Object} state - Redux state
 * @param {Object} action - Redux action
 */
export function errorsByService(state = {}, action = {}) {
  const { isFeathersError, service } = action || {}
  if (isFeathersError && service) {
    return {
      ...state,
      [service]: state[service]
        ? state[service].concat(action.payload)
        : [action.payload],
    }
  }
  return state
}

const SERVICES_NAMES = [
  'deals',
  'categories',
  'dealsCategories',
  'products',
  'retailers',
]

// function serviceNamesToReducers(serviceNames, services) {
//   return reduce(
//     serviceNames,
//     (acc, serviceName) => {
//       if (!services[serviceName]) {
//         return acc
//       }
//       return {
//         ...acc,
//         [serviceName]: services[serviceName].reducer,
//       }
//     },
//     {},
//   )
// }

// let services

const client = getFeathersClient()
// services = reduxifyServices(client, SERVICES_NAMES)

export default {
  auth: feathersAuthentication.reducer,
  entityIds,
  entities,
  serviceTotals,
  errorsByService,
  // ...serviceNamesToReducers(SERVICES_NAMES, services),
}

// NOTE: Verify if Scott had a use for this
// export function getServices() {
//   return services
// }
