import { schema } from 'normalizr'

// Define a users schema
export const user = new schema.Entity('users')

export const users = new schema.Array(user)

// Define your comments schema
export const category = new schema.Entity('categories', {
  author: user,
})

export const categories = new schema.Array(category)

export const product = new schema.Entity('products', {
  author: user,
})
export const products = new schema.Array(product)

export const retailer = new schema.Entity('retailers')

export const deal = new schema.Entity('deals', {
  categories: [category],
  retailer,
  product,
})

export const deals = new schema.Array(deal)

export const card = new schema.Entity('cards')

export const cards = new schema.Array(card)

export const retailers = new schema.Array(retailer)

export const wishlistItem = new schema.Entity(
  'wishlist',
  { product },
  { idAttribute: 'productId' },
)

export const wishlist = new schema.Array(wishlistItem)

export const alert = new schema.Entity('alerts', {}, { idAttribute: 'dealId' })
export const alerts = new schema.Array(alert)

export const analytics = new schema.Entity(
  'analytics',
  {},
  {
    idAttribute: () => 'all',
  },
)

export const collection = new schema.Entity('collections', {
  products: [product],
})
export const collections = new schema.Array(collection)
