export default function(state = { sidebarOpen: false }, action) {
  switch (action.type) {
    case 'CONFIG_UPDATE':
      return {
        ...state,
        ...action.config,
      }
    default:
      return state
  }
}
