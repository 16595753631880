export default theme => ({
  root: {
    position: 'absolute',
    bottom: 0,
    height: theme.spacing.unit * 5,
  },
  words: {
    marginBottom: theme.spacing.unit * 3,
  },
})
