import {
  apiUrl,
  env as configEnv,
  firebase as firebaseConfig,
} from 'shared/configs'

export function updateClientConfig() {
  let config = {
    env: configEnv,
    apiUrl,
  }

  // Get CI Version: Uses GitLab's $CI_PIPELINE_ID-$CI_JOB_ID, otherwise defaults to local
  config.ci_version = process.env.REACT_APP_CI_VERSION || 'local'

  // Get firebase project from shared config
  config.firebase_project = firebaseConfig.projectId

  window.th_config = config
}

/**
 * Copies an text from a hidden input and then optionally
 * triggers a div to show for a second, which can be used
 * for confirmation.
 *
 * @param {domElementSelector} targetEl Input to copy text from
 * @param {domElementSelector} actionEl Input to display and later hide again
 */
export const copyToClipboard = (targetEl, actionEl) => {
  let coupon = document.querySelector(targetEl)
  coupon.select()
  document.execCommand('Copy')
  if (alert && actionEl) {
    clearTimeout(window.copyToClipboardTimeout)
    let alert = document.querySelector(actionEl)
    alert.style.display = 'inline'
    window.copyToClipboardTimeout = setTimeout(() => {
      alert.style.display = 'none'
    }, 1000)
  }
}
