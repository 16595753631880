export default theme => ({
  root: {
    ...theme.flexColumnCenter,
    marginTop: theme.spacing.unit * 6,
  },
  paper: {
    ...theme.flexColumnCenter,
    padding: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
  },
})
