export const env = "production";

export const apiUrl = "https://api.thrifter.com";

export const publisherUrl = "https://publisher.thrifter.com";

export const firebase = {
  apiKey: "AIzaSyAdsgWqcH-v1Gy0_LDO1MPLFYxxuFwnTao",
  authDomain: "thrifter-production.firebaseapp.com",
  databaseURL: "https://thrifter-production.firebaseio.com",
  projectId: "thrifter-production",
  storageBucket: "thrifter-production.appspot.com",
  messagingSenderId: "953559497652",
};

export const sentryDsn = "https://4412efc3b67144e8aca91769f6bc2605@sentry.io/1406158";

export const publicVapidKey = "";

export const algolia = {
  APPLICATION_ID: "QEKHOTGUDR",
  SEARCH_ONLY_API_KEY: "ZGYzYWIzOWFmMzU2OTFhMWNhOGI3YTBkOWE1ODNiZjJkMzFhYTliNmY0Mjk4ZDk5ZTM0ZGE1ZWVmMTA2NmUzZmZpbHRlcnM9Tk9UJTIwcHVibGlzaGVkJTNEMA==",
  INDEX_PREFIX: "",
};

export const pushwoosh = {
  PUSHWOOSH_APPLICATION_ID: "00DA7-38F27",
  PUSHWOOSH_GCM_PROJECT_NUMBER: "226408000041",
};

export default { env, apiUrl, publisherUrl, firebase, sentryDsn, publicVapidKey, algolia, pushwoosh }