import { Loadable } from 'utils/components'
import { OVERVIEW_PATH as path } from 'constants/paths'

export default {
  path,
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: 'Overview' */ './components/OverviewPage'),
  }),
}
