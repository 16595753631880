import { branch, renderComponent } from 'recompose'

import LoadingSpinner from '../components/LoadingSpinner'

/**
 * Show a loading spinner when a condition is truthy. Used within
 * spinnerWhileLoading. Accepts a test function and a higher-order component.
 * @param  {Function} condition - Condition function for when to show spinner
 * @return {HigherOrderComponent}
 */
export const spinnerWhile = (condition, SpinnerComponent = LoadingSpinner) =>
  branch(condition, renderComponent(SpinnerComponent))
