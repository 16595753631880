export default theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 6,
  },
  toolbar: theme.mixins.toolbar,
})
