import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { OVERVIEW_PATH } from 'constants/paths'
import FmbmFooter from 'components/FmbmFooter'
import Button from '@material-ui/core/Button'

function Home({ classes }) {
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography
          className={classes.link}
          variant="h2"
          component="h1"
          href="#"
        >
          Welcome
        </Typography>
        <Typography
          className={classes.link}
          variant="h6"
          component="h2"
          href="#"
        >
          Publisher Application
        </Typography>
        <Button component={Link} to={OVERVIEW_PATH}>
          Go To Overview
        </Button>
      </Paper>
      <FmbmFooter />
    </div>
  )
}

Home.propTypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles)
}

export default Home
