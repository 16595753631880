import { omit } from 'lodash'
import { normalize } from 'normalizr'
import { singular } from 'pluralize'
import { getFeathersClient } from 'shared/api/feathers'
import * as SCHEMAS from './schemas'

// TODO: Replace me w/a polyfill (RN on android doesn't have Symbol)
const Symbol = v => v

// Action key that carries API call info interpreted by this Redux middleware
export const CALL_FEATHERS_API = Symbol('Call Feathers')
export const FEATHERS_API_ERROR = 'FEATHERS_API_ERROR'

export function feathersAction(actionData) {
  return {
    [CALL_FEATHERS_API]: actionData,
  }
}

/**
 * A Redux middleware that interprets actions with CALL_FEATHERS_API info specified.
 * Performs the call and promises when such actions are dispatched.
 */
export default function createFeathersMiddleware() {
  return store => next => action => {
    const callAPI = action[CALL_FEATHERS_API]
    if (typeof callAPI === 'undefined') {
      return next(action)
    }
    const { service, method: methodArg, id, data, params } = callAPI
    const method =
      typeof methodArg === 'function' ? methodArg(store.getState()) : methodArg

    if (typeof method !== 'string') {
      throw new Error('Specify a string method URL.')
    }

    const TYPES_PREFIX = `${service.toUpperCase()}_${method.toUpperCase()}`
    const [requestType, successType, failureType] = [
      'REQUEST',
      'SUCCESS',
      'ERROR',
    ].map(t => `${TYPES_PREFIX}_${t}`)

    next(cleanAction({ type: requestType, callAPI }))

    return callFeathersService({ service, method, id, data, params })
      .then(payload => {
        next(
          cleanAction({
            type: successType,
            service,
            method,
            payload,
          }),
        )
        if (successType === 'USERS_PATCH_SUCCESS' && data && data.products) {
          store.dispatch(
            feathersAction({ service: 'wishlist', method: 'find' }),
          )
        }
      })
      .catch(error =>
        next(
          cleanAction({
            type: failureType,
            isFeathersError: true,
            service,
            method,
            payload: {
              message: 'Something bad happened',
              ...error,
            },
          }),
        ),
      )
  }
}

/**
 * Remove call api symbol from action object
 * @param {Object} action - Redux action
 */
function cleanAction(action) {
  const finalAction = Object.assign({}, action)
  delete finalAction[CALL_FEATHERS_API]
  return finalAction
}

/**
 * Add args in correct order for feathers API based on which
 * exist.
 * @param {Object} opts - Options for arguments
 * @param {Object} opts.id - id argument for feathers
 * @param {Object} opts.data - data argument for feathers
 * @param {Object} opts.params - Params argument for feathers
 */
function getArgs({ id, data, params }) {
  let finalArgs = [params]
  if (data) {
    finalArgs = [data, ...finalArgs]
  }
  if (id) {
    finalArgs = [id, ...finalArgs]
  }
  return finalArgs
}

/**
 *
 * @param {String} service - Name of service to call
 * @param {String} method - Method to call on service
 * @param {Object} params - Params for feathers call
 */
export function callFeathersService({ service, method, id, data, params }) {
  const client = getFeathersClient()
  const serviceObj = client.service(service)

  if (typeof serviceObj[method] !== 'function') {
    console.error(`${method} is not a valid method of the ${service} service`) // eslint-disable-line no-console
  }
  const args = getArgs({ id, data, params })
  // Invoke method on feathers client service
  return serviceObj[method](...args) // eslint-disable-line
    .then(response => {
      if (!response) {
        /* eslint-disable no-console */
        console.log('no response from feathers call', response, {
          service,
          method,
        })
        /* eslint-enable no-console */
        return {}
      }

      const schemeService = ['get', 'create', 'patch', 'update'].includes(
        method,
      )
        ? singular(service)
        : service

      const schema = SCHEMAS[schemeService] || SCHEMAS.categories
      return {
        ...normalize(response.data || response, schema),
        ...omit(response, ['data']),
      }
    })
    .catch(error => {
      console.error(`Error calling feathers (service: ${service}): `, error) // eslint-disable-line no-console
      return Promise.reject(error)
    })
}
