import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase'
import { reduxFirestore } from 'redux-firestore'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/messaging'
// import { initializeMessaging } from 'utils/firebaseMessaging'
import promiseMiddleware from 'redux-promise-middleware'
import { apiMiddleware } from 'redux-api-middleware'
import makeRootReducer from './reducers'
import feathersMiddleware from 'shared/store/feathersMiddleware'
import { authenticate as authWithFeathers } from 'shared/api/feathers'
import { firebase as fbConfig } from 'shared/configs'

export default (initialState = {}) => {
  // ======================================================
  // Redux + Firebase Config (react-redux-firebase & redux-firestore)
  // ======================================================
  const rrfConfig = {
    userProfile: 'users', // root that user profiles are written to
    updateProfileOnLogin: false, // enable/disable updating of profile on login
    // presence: 'presence', // list currently online users under "presence" path in RTDB
    // sessions: null, // Skip storing of sessions
    enableLogging: false, // enable/disable Firebase Database Logging
    logListenerErrors: true,
    useFirestoreForProfile: true, // Save profile to Firestore instead of Real Time Database
    useFirestoreForStorageMeta: true, // Metadata associated with storage file uploads goes to Firestore
    onAuthStateChanged: (auth, firebase, dispatch) => {
      if (auth) {
        // Initalize messaging with dispatch
        // initializeMessaging(dispatch)
        // console.log('auth exists, calling to auth with feathers')
        // Get id token
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(idToken => authWithFeathers(idToken, { dispatch }))
      }
    },
  }

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = []

  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension())
    }
  }

  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [
    thunk.withExtraArgument(getFirebase),
    apiMiddleware,
    feathersMiddleware(),
    promiseMiddleware(),
    // This is where you add other middleware like redux-observable
  ]

  // ======================================================
  // Firebase Initialization
  // ======================================================
  // Initialize Firebase only if an fbInstance was not passed to the window (tests)
  if (!window.fbInstance) {
    firebase.initializeApp(fbConfig)
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    makeRootReducer(),
    initialState,
    compose(
      reduxFirestore(firebase),
      reactReduxFirebase(firebase, rrfConfig),
      applyMiddleware(...middleware),
      ...enhancers,
    ),
  )

  store.asyncReducers = {}

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  return store
}
