import { combineReducers } from 'redux'
import { firebaseReducer as firebase } from 'react-redux-firebase'
import { reducer as firestore } from 'redux-firestore'
import { reducer as form } from 'redux-form'
import { reducer as notifications } from 'modules/notification'
import config from './config'
import locationReducer from './location'

import feathersReducer from 'shared/store/feathersReducer'

export default function makeRootReducer(asyncReducers) {
  return combineReducers({
    // Add sync reducers here
    config,
    firebase,
    firestore,
    form,
    notifications,
    location: locationReducer,
    ...asyncReducers,
    ...feathersReducer,
  })
}

export function injectReducer(store, { key, reducer }) {
  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}
