import Loadable from 'react-loadable'
import LoadingSpinner from 'shared/components/LoadingSpinner'

export default {
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: 'NotFound' */ './components/NotFoundPage'),
    loading: LoadingSpinner,
  }),
}
