import io from 'socket.io-client'
import feathers from '@feathersjs/client'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import feathersReduxifyAuthentication from 'feathers-reduxify-authentication'
import rest from '@feathersjs/rest-client'
import config, { apiUrl } from '../configs'

const isWebApp = typeof window !== 'undefined' && window.localStorage
const app = feathers()

// TODO: server is unaware of api url for INITIAL_STATE
//   process.env.REACT_APP_TH_CONFIG &&
//   JSON.parse(process.env.REACT_APP_TH_CONFIG).isLocal
// )
// const apiUrl = 'http://localhost:3030'
// if (isWebApp) socketConfig = { transports: ['websocket'] }

if (config.clientProvider == 'socket') {
  const socket = io(apiUrl)
  app.configure(
    socketio(socket, {
      timeout: 7000,
    }),
  )
} else if (isWebApp) {
  const restClient = rest(apiUrl)
  app.configure(restClient.fetch(window.fetch))
} else {
  const restClient = rest(apiUrl)
  const axios = require('axios')
  app.configure(restClient.axios(axios))
}

// Web only
if (isWebApp) {
  app.configure(
    auth({
      cookie: 'feathers-jwt',
      storage: window.localStorage,
    }),
  )
}

export const feathersAuthentication = feathersReduxifyAuthentication(
  app,
  {
    isUserAuthorized: user => {
      return user.firebaseauthId
    },
  }, // NOTE: Right now, lets just make sure they have a matching fb account. Later we can check if they're verified, etc
)

export function getFeathersClient() {
  return app
}

export function getSocketClient() {
  return socket
}

export async function authenticate(idToken, store) {
  if (store) {
    await store.dispatch(
      feathersAuthentication.authenticate({
        strategy: 'firebaseauth',
        token: idToken,
      }),
    )
  } else {
    // leave this for a bit, to gain insite as to whether or not we're calling this w/out store
    console.log('authenticate requires store, for dispatching')
  }
}

export default app
